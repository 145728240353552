import React from 'react';

const Privacy = () => {
  return (
    <div className="p-0">
      <div className="bg-gray-50 py-16 px-6">
      <div className="container mx-auto max-w-4xl bg-white p-8 rounded-lg shadow-md">
        <h1 className="text-4xl font-bold mb-8 text-center text-blue-600">Politica de Confidențialitate</h1>

        <div className="space-y-8">
          {/* Section 1: Introducere */}
          <section>
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">1. Introducere</h2>
            <p className="text-gray-700">
              Această politică de confidențialitate descrie modul în care colectăm, utilizăm și protejăm informațiile tale personale atunci când folosești site-ul "Stop Pacanele". Prin utilizarea site-ului nostru, îți exprimi acordul cu privire la colectarea și utilizarea informațiilor tale în conformitate cu această politică.
            </p>
          </section>

          {/* Section 2: Informațiile Colectate */}
          <section>
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">2. Informațiile Colectate</h2>
            <p className="text-gray-700">
              Colectăm informații personale limitate necesare pentru a-ți oferi acces la ghidul nostru și pentru a-ți gestiona contul. Informațiile pe care le colectăm includ:
            </p>
            <ul className="list-disc list-inside text-gray-700">
              <li>Adresa de email</li>
              <li>Parola de cont</li>
              <li>Orice informație suplimentară pe care o oferi în mod voluntar (ex. feedback, întrebări, sugestii)</li>
            </ul>
          </section>

          {/* Section 3: Utilizarea Informațiilor */}
          <section>
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">3. Utilizarea Informațiilor</h2>
            <p className="text-gray-700">
              Utilizăm informațiile tale personale doar pentru scopurile pentru care le-ai oferit, inclusiv:
            </p>
            <ul className="list-disc list-inside text-gray-700">
              <li>Pentru a-ți crea și gestiona contul</li>
              <li>Pentru a-ți oferi acces la ghidul nostru</li>
              <li>Pentru a răspunde la întrebări, feedback sau sugestii</li>
              <li>Pentru a-ți trimite comunicări legate de contul tău (ex. actualizări, modificări ale termenilor și condițiilor)</li>
            </ul>
          </section>

          {/* Section 4: Securitatea Datelor */}
          <section>
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">4. Securitatea Datelor</h2>
            <p className="text-gray-700">
              Luăm măsuri rezonabile pentru a proteja informațiile tale personale împotriva accesului neautorizat, utilizării, divulgării sau distrugerii. Cu toate acestea, nicio metodă de transmitere a datelor pe internet sau metodă de stocare electronică nu este complet sigură, iar noi nu putem garanta securitatea absolută a informațiilor tale.
            </p>
          </section>

          {/* Section 5: Stocarea și Păstrarea Datelor */}
          <section>
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">5. Stocarea și Păstrarea Datelor</h2>
            <p className="text-gray-700">
              Informațiile tale personale sunt stocate în mod securizat și sunt păstrate numai atât timp cât este necesar pentru scopurile pentru care au fost colectate sau în conformitate cu obligațiile legale aplicabile.
            </p>
          </section>

          {/* Section 6: Drepturile Utilizatorului */}
          <section>
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">6. Drepturile Utilizatorului</h2>
            <p className="text-gray-700">
              În calitate de utilizator, ai dreptul de a:
            </p>
            <ul className="list-disc list-inside text-gray-700">
              <li>Accesa informațiile tale personale stocate de noi</li>
              <li>Solicita corectarea oricăror informații inexacte sau incomplete</li>
              <li>Solicita ștergerea datelor tale personale (cu excepția cazurilor în care legea ne impune să le păstrăm)</li>
              <li>Restricționa sau obiecta la prelucrarea datelor tale</li>
            </ul>
            <p className="text-gray-700">
              Pentru a-ți exercita aceste drepturi, te rugăm să ne contactezi la <a href="mailto:support@stoppacanele.com" className="text-blue-500 underline">support@stoppacanele.com</a>.
            </p>
          </section>

          {/* Section 7: Cookies și Tehnologii Similare */}
          <section>
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">7. Cookies și Tehnologii Similare</h2>
            <p className="text-gray-700">
              Site-ul nostru utilizează cookies pentru a îmbunătăți experiența utilizatorului. Cookies sunt mici fișiere text stocate pe dispozitivul tău atunci când vizitezi site-ul nostru. Acestea ne ajută să analizăm traficul pe site, să îmbunătățim funcționalitatea și să personalizăm conținutul.
            </p>
            <p className="text-gray-700">
              Poți alege să accepți sau să respingi cookies prin setările browserului tău. Reține că blocarea cookies poate afecta funcționalitatea site-ului.
            </p>
          </section>

          {/* Section 8: Terți */}
          <section>
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">8. Dezvăluirea către Terți</h2>
            <p className="text-gray-700">
              Nu vindem, nu închiriem și nu partajăm informațiile tale personale cu terți în scopuri de marketing. Putem dezvălui informațiile tale către terți numai în următoarele situații:
            </p>
            <ul className="list-disc list-inside text-gray-700">
              <li>Cu consimțământul tău</li>
              <li>În cazul în care legea ne obligă</li>
              <li>Pentru a proteja drepturile, proprietatea sau siguranța companiei noastre sau a altor persoane</li>
            </ul>
          </section>

          {/* Section 9: Modificări ale Politicii */}
          <section>
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">9. Modificări ale Politicii de Confidențialitate</h2>
            <p className="text-gray-700">
              Ne rezervăm dreptul de a modifica această politică de confidențialitate în orice moment. Orice modificări vor fi publicate pe această pagină, iar utilizarea continuă a site-ului după astfel de modificări va constitui acceptarea ta a noilor termeni.
            </p>
          </section>

          {/* Section 10: Contact */}
          <section>
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">10. Contact</h2>
            <p className="text-gray-700">
              Pentru întrebări sau preocupări legate de această politică de confidențialitate, te rugăm să ne contactezi la adresa de email: <a href="mailto:support@stoppacanele.com" className="text-blue-500 underline">support@stoppacanele.com</a>.
            </p>
          </section>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Privacy;
