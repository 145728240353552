import {useAuth} from "../contexts/AuthContext";

export default function PrivateRoute({ children }) {
    const { currentUser } = useAuth();
    if(!currentUser){
        window.location.href = "/login";
        return false;
    }

    return children
}
