import React, { useState } from 'react';
import {Link, useNavigate} from 'react-router-dom';
import Logo from '../assets/logo3.png';
import {useAuth} from "../contexts/AuthContext";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { currentUser, logout } = useAuth();
    const navigate = useNavigate()

    const handleLogout = () => {
        logout();
        setIsOpen(false);
        navigate('/');
    }

  return (
    <header className="sticky top-0 bg-white shadow-md flex justify-between items-center p-4 z-10">
      <div className="ml-10">
        <Link to="/">
          <img src={Logo} alt="Logo" className="w-40 h-30" />
        </Link>
      </div>
      {/* Desktop Menu */}
      <nav className="mr-20 hidden md:flex space-x-8 font-bold">
        <Link to="/" className="text-black">Acasa</Link>
        <Link to="/despre" className="text-black">Despre</Link>
        <Link to="/carte" className="text-black">Acceseaza Cartea</Link>
          {!currentUser &&
              <>
                    <Link to="/login" className="text-black">Logare</Link>
                    <Link to="/register" className="text-black">Inregistrare</Link>
              </>
          }
          {currentUser &&
              <>
                  <Link to="/profil" className="text-black">
                      {currentUser.email}
                  </Link>
                  <p onClick={() => handleLogout()} className="text-black cursor-pointer">
                      Logout
                  </p>
              </>
          }

      </nav>
      {/* Mobile Menu Button */}
      <div className="md:hidden mr-4">
        <button onClick={() => setIsOpen(!isOpen)} className="focus:outline-none">
          <span className="text-black">&#9776;</span>
        </button>
      </div>
      {/* Mobile Menu */}
      {isOpen && (
        <div className="absolute top-0 right-0 w-1/2 h-screen bg-black flex flex-col items-center space-y-4 pt-10">
          {/* Close Button */}
          <button onClick={() => setIsOpen(false)} className="absolute top-4 right-4 text-2xl text-white">
            &times;
          </button>
          {/* Mobile Menu Links */}
          <Link to="/" onClick={() => setIsOpen(false)} className="text-white font-bold">Acasa</Link>
          <Link to="/despre" onClick={() => setIsOpen(false)} className="text-white font-bold">Despre</Link>
          <Link to="/carte" onClick={() => setIsOpen(false)} className="text-white font-bold">Acceseaza Cartea</Link>

            {!currentUser &&
                <>
                    <Link to="/login" onClick={() => setIsOpen(false)} className="text-white font-bold">Logare</Link>
                    <Link to="/register" onClick={() => setIsOpen(false)} className="text-white font-bold">Inregistrare</Link>
                </>
            }
            {currentUser &&
                <>
                    <Link to="/profil" onClick={() => setIsOpen(false)} className="text-white font-bold">
                        Profil
                    </Link>
                    <p onClick={() => handleLogout()} className="text-white font-bold">
                        Logout
                    </p>
                </>

            }
        </div>
      )}
    </header>
  );
};

export default Header;
