import { createContext, useContext, useState, useEffect } from 'react';
import { auth, db } from '../utils/firebase';
import { onAuthStateChanged, getIdToken } from 'firebase/auth';
import {doc, getDoc} from "firebase/firestore";

const AuthContext = createContext();

export const useAuth = () => {
    return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [loaded, setLoaded] = useState(false);
    const [token, setToken] = useState(null);
    const [hasPaid, setHasPaid] = useState(false);

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(auth, async (user) => {
            if (user) {
                await setCurrentUser(user);
                const token = await getIdToken(user);
                await setToken(token);
                const hasPaidData = await checkPaymentExists(user.uid);
                setHasPaid(hasPaidData);
                localStorage.setItem('token', token);
            } else {
                setToken(null);
                localStorage.removeItem('token');
            }
            setLoaded(true);
        });

        return unsubscribe;
    }, []);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            setToken(token);
        }
    }, []);


    const recheckPayment = async () => {
        console.log('recheckPayment', currentUser)
        if(currentUser) {
            const hasPaidData = await checkPaymentExists(currentUser.uid);
            console.log('hasPaidData', hasPaidData);
            setHasPaid(hasPaidData);
        }
    }

    const checkPaymentExists = async (userId) => {
        try {
            const paymentRef = doc(db, 'payments', userId);  // Get a reference to the document
            const paymentDoc = await getDoc(paymentRef);  // Fetch the document

            if (paymentDoc.exists()) {
                // console.log("Payment exists for this user:", paymentDoc.data());
                return true;
            } else {
                // console.log("No payment found for this user.");
                return false;
            }
        } catch (error) {
            // console.error("Error checking payment: ", error);
            return false;
        }
    };

    const logout = async () => {
        await auth.signOut();
        await setCurrentUser(null);
        await setToken(null);
        await localStorage.removeItem('token');
    }

    const value = {
        currentUser,
        token,
        loaded,
        setCurrentUser,
        logout,
        hasPaid,
        recheckPayment,
    };


    return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};