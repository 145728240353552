import React from 'react';

const Terms = () => {
  return (
    <div className="p-0">
      <div className="bg-gray-50 py-16 px-6">
      <div className="container mx-auto max-w-4xl bg-white p-8 rounded-lg shadow-md">
        <h1 className="text-4xl font-bold mb-8 text-center text-blue-600">Termeni și Condiții</h1>
        
        <div className="space-y-8">
          {/* Section 1: Introduction */}
          <section>
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">1. Introducere</h2>
            <p className="text-gray-700">
              Bine ai venit la "Stop Pacanele"! Acești termeni și condiții guvernează utilizarea site-ului nostru și a ghidului informativ pentru a renunța la jocurile de noroc. Accesând sau folosind site-ul și serviciile noastre, ești de acord să respecți acești termeni și condiții. 
            </p>
          </section>

          {/* Section 2: Scopul Ghidului */}
          <section>
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">2. Scopul Ghidului</h2>
            <p className="text-gray-700">
              Ghidul oferit de "Stop Pacanele" este un material informativ care promovează metode de a renunța la jocurile de noroc. Orice informație legată de mașinile de tip slot nu este garantată a fi 100% exactă și poate diferi în situații reale. 
            </p>
          </section>

          {/* Section 3: Disclaimer */}
          <section>
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">3. Disclaimer</h2>
            <p className="text-gray-700">
              Ghidul nu promite în mod garantat că utilizatorul va renunța la jocurile de noroc în urma utilizării sale. Este destinat să ofere sprijin și motivație, însă rezultatele pot varia în funcție de situația fiecărei persoane.
            </p>
          </section>

          {/* Section 4: Proprietate Intelectuală */}
          <section>
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">4. Proprietate Intelectuală</h2>
            <p className="text-gray-700">
              Toate textele și imaginile din cadrul ghidului și de pe site sunt proprietatea ADAIE INNOVATIONS SRL. Orice reproducere sau utilizare a acestora fără acordul companiei este strict interzisă.
            </p>
          </section>

          {/* Section 5: Politica de Plată și Rambursare */}
          <section>
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">5. Politica de Plată și Rambursare</h2>
            <p className="text-gray-700">
              După efectuarea plății, accesul la ghid este oferit pe viață. Nu există rambursări odată ce plata a fost efectuată.
            </p>
          </section>

          {/* Section 6: Dreptul de a Modifica Termenii */}
          <section>
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">6. Dreptul de a Modifica Termenii</h2>
            <p className="text-gray-700">
              ADAIE INNOVATIONS SRL își rezervă dreptul de a modifica acești termeni și condiții în orice moment, fără notificare prealabilă. Orice modificări vor fi publicate pe această pagină și vor intra în vigoare imediat.
            </p>
          </section>

          {/* Section 7: Datele Utilizatorului */}
          <section>
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">7. Datele Utilizatorului</h2>
            <p className="text-gray-700">
              Crearea contului de utilizator necesită doar adresa de email și o parolă. Site-ul "Stop Pacanele" nu colectează date sensibile de la utilizatori.
            </p>
          </section>

          {/* Section 8: Partajarea Contului */}
          <section>
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">8. Partajarea Contului</h2>
            <p className="text-gray-700">
              Utilizatorul nu are dreptul să partajeze contul cu alte persoane. Această încălcare va fi sancționată prin închiderea contului.
            </p>
          </section>

          {/* Section 9: Protecția Conținutului */}
          <section>
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">9. Protecția Conținutului</h2>
            <p className="text-gray-700">
              Conținutul ghidului nu poate fi copiat, distribuit sau partajat fără acordul scris al ADAIE INNOVATIONS SRL. Orice încălcare a acestor termeni poate duce la acțiuni legale.
            </p>
          </section>

          {/* Section 10: Limitarea Responsabilității */}
          <section>
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">10. Limitarea Responsabilității</h2>
            <p className="text-gray-700">
              ADAIE INNOVATIONS SRL nu este responsabilă pentru eventuale consecințe sau daune care ar putea apărea în urma utilizării ghidului sau a site-ului "Stop Pacanele".
            </p>
          </section>

          {/* Section 11: Contact */}
          <section>
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">11. Contact</h2>
            <p className="text-gray-700">
              Dacă ai întrebări sau preocupări legate de acești termeni și condiții, te rugăm să ne contactezi la adresa de email: <a href="mailto:support@stoppacanele.com" className="text-blue-500 underline">support@stoppacanele.com</a>.
            </p>
          </section>
        </div>
      </div>
    </div>
    </div>
  );
};

export default Terms;
