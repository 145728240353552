import React from 'react';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <footer className="bg-[#0c0a09] text-white p-4 flex justify-between">
      <div className="text-center w-full md:w-auto">
        &copy; 2024 Stop Pacanele. Toate drepturile rezervate.
      </div>
      <nav className="hidden md:flex space-x-4">
        <Link to="/terms" className="text-white">Termeni și Condiții</Link>
        <Link to="/privacy" className="text-white">Politica de confidențialitate</Link>
        <Link to="/despre" className="text-white">Despre</Link>
      </nav>
    </footer>
  );
};

export default Footer;
