import React, {useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {auth, errorCodes} from '../utils/firebase';
import { createUserWithEmailAndPassword } from 'firebase/auth';
import {useAuth} from "../contexts/AuthContext";

const Register = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const navigate = useNavigate()
  const { setCurrentUser } = useAuth();

  const handleRegister = async (e) => {
    e.preventDefault();
    try {
      const authData = await createUserWithEmailAndPassword(auth, email, password);
      // const res = await Api.registerUser(email, username, authData.user.uid);
      setCurrentUser(authData.user);
      localStorage.setItem('token', authData.user.accessToken);
      navigate("/");
    } catch (error) {
      console.log("error", error.code);
      if(errorCodes[error.code]){
        setError(errorCodes[error.code]);
      }else {
        setError(error.code);
      }
      setTimeout(()=>{
        setError('');
      }, 5000)
    }
  };

  return (
      <div className="flex justify-center items-center h-[calc(100vh-250px)] mx-4 lg:mx-0 text-black">
        <form className="p-8 bg-white shadow-lg rounded-lg max-w-md w-full" onSubmit={handleRegister}>
          <h2 className="text-2xl font-bold mb-4">Inregistrare</h2>
          <input
              type="email"
              placeholder="Email"
              className="block w-full p-2 mb-4 border rounded"
              required
              value={email}
              onChange={(e) => setEmail(e.target.value)}
          />
          <input
              type="password"
              placeholder="Password"
              className="block w-full p-2 mb-4 border rounded"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
          />
          <label className="inline-flex items-center mb-4">
            <input type="checkbox" className="form-checkbox"/>
            <a href='/terms'><span className="ml-2 text-[#000]">Accept termenii si conditiile</span></a>
          </label>
          <button className="w-full bg-green-500 text-white p-2 rounded">Inregistreaza-te</button>
          {error.length > 0 &&
              <div className="text-red-500 p-2 text-center">
                {error}
              </div>
          }
          <div className="mt-4 text-center">
            Ai cont? <Link to="/login" className="text-blue-500">Login</Link>
          </div>
        </form>
      </div>
  );
};

export default Register;
