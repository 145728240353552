import React from 'react';
import {useAuth} from "../contexts/AuthContext";
import StripeCheckout from "../components/StripeCheckout";
import Book from "../components/Book";

const AcceseazaCartea = () => {
    const { hasPaid } = useAuth();


    return (
        <div className="mt-4 lg:p-6">
            <section id="about-us" className="bg-gray-100 py-12 rounded-xl text-gray-800">
                <div className="container mx-auto px-6" id="main">
                    <h1 className="text-4xl font-bold text-center text-gray-800 mb-12">Cartea stop pacanele</h1>
                    {hasPaid &&
                        <Book/>
                    }
                    {!hasPaid &&
                        <StripeCheckout/>
                    }
                </div>
            </section>
        </div>
    );
};

export default AcceseazaCartea;