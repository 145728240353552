import React from 'react';
import Carte from '../assets/carte1.png'
import Family from '../assets/family1.png'
import Comunity from '../assets/comunity1.png'

import { Swiper, SwiperSlide } from 'swiper/react';
import {Pagination,Autoplay  } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import {Link} from "react-router-dom";


const reviews = [
  {
    message: "Datorită Stop Pacanele, am reușit să-mi refac viața. Familia mea m-a sprijinit și acum mă bucur de fiecare moment.",
    name: "Mihai P."
  },
  {
    message: "Ghidul lor m-a ajutat să găsesc puterea de a spune NU jocurilor de noroc. Este cel mai bun lucru pe care l-am făcut pentru mine.",
    name: "Ana L."
  },
  {
    message: "Stop Pacanele mi-a arătat că există viață după dependență. Recomand cu căldură această platformă.",
    name: "Ion C."
  },
  {
    message: "Nu credeam că pot scăpa de această dependență, dar cu ajutorul Stop Pacanele, am reușit!",
    name: "Alex D."
  },
  {
    message: "Mulțumesc pentru sprijinul acordat. Acum am o viață mai bună, departe de jocurile de noroc.",
    name: "Maria F."
  },
  {
    message: "Platforma mi-a oferit sfaturile de care aveam nevoie pentru a spune STOP!",
    name: "Cristian G."
  },
  {
    message: "O adevărată schimbare în viața mea, datorită ghidului oferit de Stop Pacanele.",
    name: "Daniela S."
  },
  {
    message: "Nu m-am simțit niciodată mai liber de când am renunțat la jocurile de noroc.",
    name: "George V."
  },
  {
    message: "Recomand această platformă tuturor celor care doresc să facă o schimbare reală în viața lor.",
    name: "Elena M."
  },
  {
    message: "O experiență incredibilă. Stop Pacanele m-a ajutat să devin o persoană mai bună.",
    name: "Vlad R."
  }
];




const Home = () => {
  return (
    <div className="bg-gray-100 p-8">
      {/* Hero Section */}
      <section className="bg-[#1c1917] text-white py-12">
        <div className="container mx-auto text-center">
          <h1 className="text-5xl font-bold mb-6">Stop Pacanele</h1>
          <p className="text-lg mb-8 p-5">
            Ajutăm oamenii să renunțe la jocurile de noroc și să își regăsească echilibrul în viață.
          </p>
          <Link to="/carte" className="bg-[#e74c3c] text-[#FFF] px-8 py-3 rounded-lg shadow-lg font-semibold">
            Descoperă Ghidul Nostru
          </Link>
        </div>
      </section>

      {/* Introduction Section */}
      <section className="py-16 bg-white">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-3xl font-semibold mb-6 text-[#1c1917]">Despre Stop Pacanele</h2>
          <p className="text-gray-600 mb-12">
            Suntem aici pentru a te ajuta să renunți la dependența de jocurile de noroc și să îți refaci viața. Cu resurse utile, ghiduri practice și sprijin, te încurajăm să găsești puterea de a schimba direcția și de a redescoperi lucrurile care contează cu adevărat.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="bg-gray-50 p-6 rounded-lg shadow-md">
            <div className="flex justify-center">
                <img src={Carte} alt="" className="w-38 h-48 object-cover rounded-lg mb-4" />
            </div>
              <h3 className="text-xl font-semibold mb-2 text-[#1c1917]">Ghid Complet</h3>
              <p className="text-gray-600">
                Descoperă ghidul nostru care te va ajuta să renunți la jocurile de noroc pentru totdeauna.
              </p>
            </div>
            <div className="bg-gray-50 p-6 rounded-lg shadow-md">
            <div className="flex justify-center">
                <img src={Family} alt="" className="w-38 h-48 object-cover rounded-lg mb-4" />
            </div>
              <h3 className="text-xl font-semibold mb-2 text-[#1c1917]">Sprijin pentru Familie</h3>
              <p className="text-gray-600">
                Împreună, putem reconstrui relațiile de familie și ne putem concentra pe lucrurile cu adevărat importante.
              </p>
            </div>
            <div className="bg-gray-50 p-6 rounded-lg shadow-md">
            <div className="flex justify-center">
                <img src={Comunity} alt="" className="w-38 h-48 object-cover rounded-lg mb-4" />
            </div>
              <h3 className="text-xl font-semibold mb-2 text-[#1c1917]">Comunitate de Suport</h3>
              <p className="text-gray-600">
                Alătură-te comunității noastre și găsește sprijin în oameni care împărtășesc aceeași provocare.
              </p>
            </div>
          </div>
        </div>
      </section>

      {/* Featured Guide Section */}
      <section className="py-16 bg-[#1c1917]">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-3xl font-semibold mb-6">Ghidul Care Te Va Ajuta Să Renunți la Jocurile de Noroc</h2>
          <p className="text-lg mb-12">
            Cartea noastră este un ghid complet care te va învăța pașii necesari pentru a te elibera de dependența de jocuri de noroc și pentru a trăi o viață împlinită și fericită.
          </p>
          <Link to="/carte" className="bg-[#e74c3c] text-white px-8 py-3 rounded-lg shadow-lg font-semibold">
            Citește Ghidul Nostru
          </Link>
        </div>
      </section>

      {/* Testimonial Section */}
      {/* <section className="py-16 bg-white">
        <div className="container mx-auto px-4">
          <h2 className="text-3xl font-semibold text-center mb-12 text-[#1c1917]">Ce spun cei care au reușit</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="bg-gray-50 p-6 rounded-lg shadow-md">
              <p className="text-gray-600 mb-4">"Datorită Stop Pacanele, am reușit să-mi refac viața. Familia mea m-a sprijinit și acum mă bucur de fiecare moment."</p>
              <h3 className="text-lg font-semibold">Mihai P.</h3>
            </div>
            <div className="bg-gray-50 p-6 rounded-lg shadow-md">
              <p className="text-gray-600 mb-4">"Ghidul lor m-a ajutat să găsesc puterea de a spune NU jocurilor de noroc. Este cel mai bun lucru pe care l-am făcut pentru mine."</p>
              <h3 className="text-lg font-semibold">Ana L.</h3>
            </div>
            <div className="bg-gray-50 p-6 rounded-lg shadow-md">
              <p className="text-gray-600 mb-4">"Stop Pacanele mi-a arătat că există viață după dependență. Recomand cu căldură această platformă."</p>
              <h3 className="text-lg font-semibold">Ion C.</h3>
            </div>
          </div>
        </div>
      </section> */}

    <section className="py-16 bg-white">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-semibold text-center mb-12 text-[#1c1917]">Ce spun cei care au reușit</h2>
        
        <Swiper
          modules={[Pagination, Autoplay]}
          spaceBetween={20}
          slidesPerView={1}
          autoplay={{ delay: 3000, disableOnInteraction: false }}
          pagination={{ clickable: true }}
          breakpoints={{
            640: {
              slidesPerView: 1,
            },
            768: {
              slidesPerView: 2,
            },
            1024: {
              slidesPerView: 3,
            },
          }}
          className="swiper-container"
          style={{ paddingBottom: '40px' }}
        >
          {reviews.map((review, index) => (
            <SwiperSlide key={index} className="bg-gray-50 p-6 rounded-lg shadow-md">
              <p className="text-gray-600 mb-4">"{review.message}"</p>
              <h3 className="text-lg font-semibold">{review.name}</h3>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </section>

      {/* Call to Action Section */}
      <section className="py-16 bg-[#1c1917] text-white">
        <div className="container mx-auto text-center max-w-screen-md w-full">
          <h2 className="text-3xl font-semibold mb-6 p-2">Ești Pregătit Să Faci Primul Pas?</h2>
          <p className="text-lg mb-6 p-5">
            Nu ești singur. Suntem aici pentru a te ajuta să renunți la jocurile de noroc și să îți reconstruiești viața.
          </p>
          <form action="https://formspree.io/f/YOUR_FORM_ID" method="POST" className="space-y-4">
            <div className='mx-4 lg:mx-0'>
              <label className="block text-sm font-medium" htmlFor="name">Nume</label>
              <input
                type="text"
                name="name"
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm text-slate-950 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                placeholder="Introdu numele tău"
                required
              />
            </div>

            <div className='mx-4 lg:mx-0'>
              <label className="block text-sm font-medium" htmlFor="email">Email</label>
              <input
                type="email"
                name="email"
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm text-slate-950 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                placeholder="Introdu adresa ta de email"
                required
              />
            </div>

            <div className='mx-4 lg:mx-0'>
              <label className="block text-sm font-medium" htmlFor="message">Mesaj</label>
              <textarea
                name="message"
                rows="4"
                className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm text-slate-950 focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                placeholder="Scrie mesajul tău"
                required
              ></textarea>
            </div>

            <div className="flex justify-end mx-4 lg:mx-0">
              <button
                type="submit"
                className="bg-[#e74c3c] text-white px-4 py-2 rounded-lg shadow-lg hover:bg-[#c0392b] font-semibold"
              >
                Trimite
              </button>
            </div>
          </form>
        </div>
      </section>
    </div>
  );
};

export default Home;
