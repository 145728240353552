import {useEffect, useState, useLayoutEffect} from "react";
import {useAuth} from "../contexts/AuthContext";
import CryptoJS from 'crypto-js';
import Api from "../utils/api";
import {Document, Page, pdfjs} from 'react-pdf'
import Loading from "./Loading";

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
    'pdfjs-dist/build/pdf.worker.min.mjs',
    import.meta.url,
).toString();

const Book = () => {
    const [pdfUrl, setPdfUrl] = useState("");
    const [loaded, setLoaded] = useState(false);
    const [width, setWidth] = useState(window.innerWidth * 0.9);
    const [numPages, setNumPages] = useState(1);

    const {currentUser} = useAuth();

    const encryptData = (data, secretKey) => {
        const encryptedData = CryptoJS.AES.encrypt(JSON.stringify(data), secretKey).toString();
        return encryptedData;
    };

    useEffect(()=>{
        const run = async()=> {
            setLoaded(true);
            const dataToSend = {
                user_id: currentUser.uid,
                timestamp: Date.now()
            };
            const secretKey = process.env.REACT_APP_SECRET_KEY_BOOK
            const encryptedString = encryptData(dataToSend, secretKey);

            const response = await Api.fetchApi(
                Api.paths.book + '/' + encodeURIComponent(encryptedString),
                {},
                {
                    'Content-Type': 'application/pdf',
                }
            );

            const blob = await response.blob();
            const url= URL.createObjectURL(blob);
            setPdfUrl(url);
        }

        if(!loaded) {
            run();
        }
    })

    useLayoutEffect(() => {
        window.addEventListener('resize', handleResize);
        handleResize();
        return () => window.removeEventListener('resize', handleResize);
    }, []);


    const handleResize = () => {
        const element = document.getElementById('main');
        if (element) {
            const newWidth = element.getBoundingClientRect().width - 48;
            setWidth(newWidth);
        }
    };

    function onDocumentLoadSuccess({ numPages }) {
        setNumPages(numPages);
    }

    return (
        <div className="items-center justify-center">
            {pdfUrl ? (
                <Document file={pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
                    {Array.from(new Array(numPages)).map((item, index) => {
                        return <Page key={index+1} pageNumber={index+1} renderAnnotationLayer={false} renderTextLayer={false} width={width}/>
                    })}
                </Document>
            ) : (
                <Loading />
            )}
        </div>
    )
}

export default Book;