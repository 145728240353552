import React from 'react';
import Cine from '../assets/cinesuntem1.png'
import Misiune from '../assets/mission1.png'
import Carte from '../assets/carte1.png'
import Family from '../assets/family2.png'
import Joinus from '../assets/joinus.png'

const Despre = () => {
  return (
    <div className="p-8">
     <section id="about-us" className="bg-gray-100 py-12 rounded-xl">
    <div className="container mx-auto px-6">
      
        <h1 className="text-4xl font-bold text-center text-gray-800 mb-12">Despre Noi</h1>
        
       
        <div className="about-section flex flex-col md:flex-row items-center mb-12">
            <div className="md:w-1/2">
                <h2 className="text-3xl font-semibold text-gray-700 mb-4">Cine Suntem?</h2>
                <p className="text-gray-600 mb-6">Bine ai venit pe Stoppacanele! Suntem aici pentru a ajuta oamenii să renunțe la jocurile de noroc, în special la pacanele, fie ele online sau fizice. Ne dorim să oferim sprijin, motivație și resurse utile celor care doresc să își recâștige controlul asupra vieții.</p>
                <p className="text-gray-600">Credem în puterea familiei și în reconectarea cu ceea ce este cu adevărat important în viață. Stoppacanele este o comunitate pentru cei care doresc să lase în urmă dependența și să găsească noi motive de bucurie și speranță.</p>
            </div>
            <div className="md:w-1/2 mt-8 md:mt-0 md:pl-12">
            <div className="flex justify-center">
                <img src={Cine} alt="" className="w-38 h-50 object-cover rounded-lg mb-4" />
            </div>
            </div>
        </div>

        <div className="about-section flex flex-col md:flex-row-reverse items-center mb-12">
            <div className="md:w-1/2">
                <h2 className="text-3xl font-semibold text-gray-700 mb-4">Misiunea Noastră</h2>
                <p className="text-gray-600 mb-6">Scopul nostru este să ajutăm cât mai mulți oameni să renunțe la jocurile de noroc prin sprijinul oferit de familie, prieteni și activități semnificative. Vrem să arătăm că există viață după jocurile de noroc și că fericirea vine din lucruri reale și durabile.</p>
            </div>
            <div className="md:w-1/2 mt-8 md:mt-0 md:pr-12">
            <div className="flex justify-center">
                <img src={Misiune} alt="" className="w-38 h-50 object-cover rounded-lg mb-4" />
            </div>
            </div>
        </div>

        <div className="about-section flex flex-col md:flex-row items-center mb-12">
            <div className="md:w-1/2">
                <h2 className="text-3xl font-semibold text-gray-700 mb-4">Cartea Care Te Va Învăța Cum Să Renunți la Jocurile de Noroc</h2>
                <p className="text-gray-600 mb-6">Pe lângă resursele și articolele noastre, Stoppacanele oferă și o carte dedicată celor care doresc să renunțe la jocurile de noroc. Această carte oferă ghiduri practice și sfaturi despre cum să înfrunți dependența, să îți refaci relațiile cu cei dragi și să trăiești o viață plină de semnificație.</p>
                <p className="text-gray-600">Cartea noastră este mai mult decât un simplu ghid; este un prieten de încredere care te va însoți pe tot parcursul drumului tău spre o viață liberă de jocuri de noroc.</p>
            </div>
            <div className="md:w-1/2 mt-8 md:mt-0 md:pl-12">
            <div className="flex justify-center">
                <img src={Carte} alt="" className="w-38 h-48 object-cover rounded-lg mb-4" />
            </div>
            </div>
        </div>
        <div className="about-section flex flex-col md:flex-row-reverse items-center mb-12">
            <div className="md:w-1/2">
                <h2 className="text-3xl font-semibold text-gray-700 mb-4">De Ce Familia Contează</h2>
                <p className="text-gray-600 mb-6">La baza recuperării se află puterea relațiilor cu cei dragi. Ne concentrăm pe importanța reconectării cu familia, care oferă sprijin emoțional și un scop mai mare în viață. Dragostea și sprijinul celor dragi sunt cheia pentru a găsi puterea de a renunța la jocurile de noroc.</p>
            </div>
            <div className="md:w-1/2 mt-8 md:mt-0 md:pr-12">
            <div className="flex justify-center">
                <img src={Family} alt="" className="w-38 h-50 object-cover rounded-lg mb-4" />
            </div>
            </div>
        </div>

      
        <div className="about-section flex flex-col md:flex-row items-center mb-12">
            <div className="md:w-1/2">
                <h2 className="text-3xl font-semibold text-gray-700 mb-4">Alătură-te Nouă</h2>
                <p className="text-gray-600 mb-6">Indiferent unde te afli pe drumul renunțării la jocurile de noroc, Stoppacanele este aici pentru tine. Îți oferim resurse, ghiduri și o comunitate de oameni care te susțin în această călătorie. Nu ești singur, iar noi te sprijinim în fiecare pas al drumului tău spre o viață mai bună.</p>
            </div>
            <div className="md:w-1/2 mt-8 md:mt-0 md:pl-12">
            <div className="flex justify-center">
                <img src={Joinus} alt="" className="w-38 h-50 object-cover rounded-lg mb-4" />
            </div>
            </div>
        </div>
    </div>
</section>

    </div>
  );
};

export default Despre;
