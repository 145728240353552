const api_url = process.env.REACT_APP_API_URL

const paths = {
    create_payment_intent: api_url + "create-payment-intent",
    save_payment: api_url + "save_payment",
    book: api_url + "book",
}

const fetchApi = async (url, data = undefined, headers = undefined) => {
    const fetchInit = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
    }
    if(data){
        fetchInit.body = JSON.stringify( data );
    }
    if(headers){
        fetchInit.headers = headers;
    }

    return await fetch(url, fetchInit);
}

const Api = {
    api_url,
    paths,
    fetchApi,
};

export default Api;