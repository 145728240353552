import React, {useState} from 'react';
import {Link, useNavigate} from 'react-router-dom';
import {auth, errorCodes} from '../utils/firebase';
import { signInWithEmailAndPassword } from 'firebase/auth';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('')
  const [error, setError] = useState('')
  const navigate = useNavigate()

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      await signInWithEmailAndPassword(auth, email, password);
      navigate("/");
    } catch (error) {
      console.log("error", error.code);
      if(errorCodes[error.code]){
        setError(errorCodes[error.code]);
      }else {
        setError(error.code);
      }
      setTimeout(()=>{
        setError('');
      }, 5000)
    }
  };

  return (
    <div className="flex justify-center items-center h-[calc(100vh-250px)] mx-4 lg:mx-0 text-black">
      <form className="p-8 bg-white shadow-lg rounded-lg max-w-md w-full" onSubmit={handleLogin}>
        <h2 className="text-2xl font-bold mb-4">Logare</h2>
        <input
            type="email"
            placeholder="Email"
            className="block w-full p-2 mb-4 border rounded"
            required
            value={email}
            onChange={(e) => setEmail(e.target.value)}
        />
        <input
            type="password"
            placeholder="Password"
            className="block w-full p-2 mb-4 border rounded"
            required
            value={password}
            onChange={(e) => setPassword(e.target.value)}
        />
        <button className="w-full bg-blue-500 text-white p-2 rounded">Logare</button>
        {error.length>0 &&
            <div className="text-red-500 p-2 text-center">
              {error}
            </div>
        }
        <div className="mt-4 text-center">
          Nu ai un cont? <Link to="/register" className="text-blue-500">Inregistreaza-te</Link>
        </div>
      </form>
    </div>
  );
};

export default Login;
