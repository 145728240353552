import React, {useEffect} from 'react';
import Header from './layout/Header';
import Footer from './layout/Footer';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Despre from './pages/Despre';
import AcceseazaCartea from './pages/AcceseazaCartea';
import Login from './pages/Login';
import Register from './pages/Register';
import Terms from './pages/Terms';
import Privacy from './pages/Privacy';
import ProfilePage from "./pages/ProfilePage";
import {useAuth} from "./contexts/AuthContext";
import PrivateRoute from "./layout/PrivateRoute";
import Loading from "./components/Loading";

function App() {
  const { loaded } = useAuth();

  useEffect(() => {
    const handleContextMenu = (e) => {
      e.preventDefault();
    };

    const handleKeyDown = (e) => {
      if (e.key === 'F12' || e.key === 'F11') {
        e.preventDefault();
      }
    };

    document.addEventListener('contextmenu', handleContextMenu);
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('contextmenu', handleContextMenu);
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  if(!loaded){
    return <Loading />
  }

  return (
    <Router>
      <div className="flex flex-col min-h-screen">
        <Header />
        <main className="flex-grow">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/despre" element={<Despre />} />
            <Route path="/login" element={<Login />} />
            <Route path="/register" element={<Register />} />
            <Route path="/terms" element={<Terms />} />
            <Route path="/privacy" element={<Privacy />} />
            <Route
                path="/carte"
                element={
                  <PrivateRoute>
                    <AcceseazaCartea />
                  </PrivateRoute>
                }
            />
            <Route
                path="/profil"
                element={
                  <PrivateRoute>
                    <ProfilePage />
                  </PrivateRoute>
                }
            />
          </Routes>
        </main>
        <Footer />
      </div>
    </Router>
  );
}

export default App;