import React, {useState} from "react";
import { updatePassword } from 'firebase/auth';
import {useAuth} from "../contexts/AuthContext";

export default function ProfilePage() {
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [successText, setSuccessText] = useState('');
    const { firebaseUser } = useAuth();

    const handleSaving = async (e) => {
        e.preventDefault();
        try {
            updatePassword(firebaseUser, password)
            setSuccessText('Parola noua a fost salvata');
        }catch (e){
            if(e.code === 'auth/requires-recent-login'){
                setError('Pentru a schimba parola trebuie sa te reloghezi.')
                setTimeout(() => {
                    setError('')
                },5000)
            }
        }
    };

    return (
        <div className="flex justify-center items-center h-[calc(100vh-250px)] mx-4 lg:mx-0 text-black">
            <form className="p-8 bg-white shadow-lg rounded-lg max-w-md w-full" onSubmit={handleSaving}>
                <h2 className="text-2xl font-bold mb-4">Schimbare parola</h2>
                <div>
                    <label
                        htmlFor="password"
                        className="block mb-2 text-sm font-medium text-gray-900"
                    >
                        Noua parola
                    </label>
                    <input
                        type="password"
                        name="username"
                        id="password"
                        className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5"
                        placeholder="***"
                        required
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                </div>

                {error && <p style={{color: 'red'}}>{error}</p>}

                {successText && <p style={{color: 'green'}}>{successText}</p>}

                <button
                    type="submit"
                    className="w-full bg-primary-600 hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
                >
                    Salveaza
                </button>
            </form>
        </div>
    );
}
