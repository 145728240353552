import React, { useState } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import Api from "../utils/api";
import {useAuth} from "../contexts/AuthContext";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_KEY);

const CheckoutForm = () => {
    const { currentUser, recheckPayment } = useAuth();
    const stripe = useStripe();
    const elements = useElements();
    const [paymentError, setPaymentError] = useState(null);
    const [paymentSuccess, setPaymentSuccess] = useState(null);
    const [loading, setLoading] = useState(false);

    const CARD_ELEMENT_OPTIONS = {
        style: {
            base: {
                color: 'black',
                fontSmoothing: 'antialiased',
                fontSize: '16px',
                width: '200px',
                padding: '50px',
                '::placeholder': {
                    color: '#555',
                },
            },
            invalid: {
                color: '#fa755a',
                iconColor: '#fa755a',
            },
        },
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        setLoading(true);

        const card = elements.getElement(CardElement);

        const { error, paymentMethod } = await stripe.createPaymentMethod({
            type: 'card',
            card: card,
        });

        if (error) {
            setPaymentError(error.message);
            return;
        }

        const response = await Api.fetchApi(
            Api.paths.create_payment_intent, {
                email: currentUser.email
            }
        );
        const { clientSecret } = await response.json();

        const { paymentIntent, error: confirmError } = await stripe.confirmCardPayment(clientSecret, {
            payment_method: paymentMethod.id,
        });

        if (confirmError) {
            setPaymentError(confirmError.message);
        } else if (paymentIntent.status === 'succeeded') {
            setPaymentSuccess('Plata a fost cu succes!');

            console.log("before", Date.now());

            await Api.fetchApi(
                Api.paths.save_payment,
                {
                    clientId: currentUser.uid,
                    clientSecret,
                    paymentMethod: paymentMethod.id
                }
            );

            console.log("after", Date.now());

            await recheckPayment();

        }
        setLoading(false);
    };

    return (
        <>
            <h2 className="text-2xl font-bold text-center text-gray-800 mb-12">
            Alege speranța, alege familia, alege dragostea.
            </h2>
            <p>Gândește-te la toate lucrurile pe care le iubești – la zâmbetul celor dragi, la momentele de liniște și pace pe care le simți alături de familia ta, la toate amintirile frumoase pe care le poți crea. Fiecare zi este o oportunitate de a trăi, de a te bucura de ceea ce contează cu adevărat, de a-ți construi o viață plină de semnificație și bucurie.</p>
            <h2 className="text-2xl font-bold text-center text-gray-800 mb-12">Este timpul să iei controlul.</h2>
            <p>Dependența nu îți controlează viața. Tu ai puterea de a decide ce urmează. Tu alegi să te ridici din întuneric, să te eliberezi de povara jocurilor de noroc și să îți îmbrățișezi adevărata valoare. Poate că drumul nu va fi ușor, dar fiecare pas înainte este o victorie, fiecare zi fără jocuri de noroc este un triumf.</p>
            <h2 className="text-2xl font-bold text-center text-gray-800 mb-12">Pretul acestui ghid este de 9.99 RON</h2>

            <form onSubmit={handleSubmit}>
                <div className="lg:p-4 mt-4">

                    <div className="grid gap-2 lg:gap-8 grid-cols-6 justify-center items-center mt-4">
                        <div className="flex-auto rounded-lg shadow-md w-full col-span-6 lg:col-span-4">
                            <CardElement options={CARD_ELEMENT_OPTIONS} className="p-4"/>
                        </div>
                        <div className="w-full col-span-6 lg:col-span-2">
                            <button
                                type="submit"
                                disabled={loading}
                                className="w-full py-2 bg-blue-500 hover:bg-blue-600 text-md text-white font-bold rounded-xl transition duration-200"
                            >
                                {loading ? 'Se proceseaza...' : 'Plateste'}
                            </button>
                        </div>
                        {paymentError && <div className="flex-auto w-full col-span-6">{paymentError}</div>}
                        {paymentSuccess && <div className="flex-auto w-full col-span-6">{paymentSuccess}</div>}
                    </div>
                </div>
            </form>

        </>


    // <form onSubmit={handleSubmit}>
    //     <CardElement/>
    //     <button type="submit" disabled={!stripe}>
    //         Pay
    //     </button>
    //     {paymentError && <div>{paymentError}</div>}
    //     {paymentSuccess && <div>{paymentSuccess}</div>}
    // </form>
)
    ;
};

const StripeCheckout = () => {
    return (
        <Elements stripe={stripePromise}>
            <CheckoutForm/>
        </Elements>
    );
};

export default StripeCheckout;